import AuthGuard from "./../../page-sections/authentication/AuthGuard";
import { FC, Fragment, ReactNode } from "react";
import { Outlet } from "react-router";

// --------------------------------------------
type DashboardLayoutProps = {
  children?: ReactNode;
};
// --------------------------------------------

const DashboardLayoutExam: FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <AuthGuard>
      <Fragment>
        <>{children || <Outlet />}</>
      </Fragment>
    </AuthGuard>
  );
};

export default DashboardLayoutExam;
