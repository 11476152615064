import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "./components/RTL";
import useSettings from "./hooks/useSettings";
import { FC } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import "./i18n";
import { SnackbarProvider } from "notistack";

const App: FC = () => {
  const content = useRoutes(routes());
  const { settings } = useSettings();

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <StyledEngineProvider injectFirst>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <RTL>
            <CssBaseline />
            {content}
          </RTL>
        </ThemeProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
};

export default App;
