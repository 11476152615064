import { Box } from "@mui/material";
import nProgress from "nprogress";
// import NProgress from "nprogress";
import { FC, useEffect } from "react";

const LoadingScreen: FC = () => {
  nProgress.configure({ showSpinner: false });

  useEffect(() => {
    nProgress.start();

    return () => {
      nProgress.done();
    };
  }, []);

  return <Box />;
};

export default LoadingScreen;
