// import { AuthResponse, mockManagerDataAuth, mockTesterDataAuth, SignIn } from '../types/auth';

import * as http from "../utils/http";

const loginUrl = "/api/auth/login";
const profileUrl = "/api/auth/profile";

export const loginService = async (signIn: any) => {
  return http
    .fetch<any, { data: any }>(`${loginUrl}`, {
      method: "post",
      data: { ...signIn },
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      // console.log("error  ---", error);
      return error;
    });
};

export const renewPass = async (password: string, token: string) => {
  return http
    .fetch<any, { data: any }>(`/api/auth/renew`, {
      method: "post",
      data: { password, token },
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const sendResetMailService = async (email: string) => {
  return http
    .fetch<any, { data: any }>(`/api/auth/reset`, {
      method: "post",
      data: { email },
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const profileService = async () => {
  return http
    .fetchWithAuth(`${profileUrl}`, {
      method: "get",
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
