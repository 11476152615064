import * as http from "../utils/http";
import { IPaging } from "./interface/common";

export const searchMessageList = async (
  page: number
): Promise<IPaging<Array<any>>> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/messeges/getMesseges/${page}`, {
      method: "get",
    })
    .then((datas: any) => {
      if (datas) {
        return {
          datas: datas.datas,
          total: datas.total,
        };
      } else {
        return {
          datas: [],
          total: 0,
        };
      }
    })
    .catch((error) => {
      return error;
    });
};

export const updateRead = async (id: number): Promise<any> => {
  return http
    .fetchWithAuth<any, { data: any }>(`/api/messeges/updateToRead/${id}`, {
      method: "put",
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      return false;
    });
};
