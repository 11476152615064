// import duotone from "../../icons/duotone";
import ElementHub from "../../icons/duotone/ElementHub";

export const navigations = [
  { type: "label", label: "admin" },
  {
    name: "practice",
    icon: ElementHub,
    children: [
      { name: "training_list", path: "/training/list" },
      { name: "exam_list", path: "/training/examList" },
      { name: "result_list", path: "/training/report" },
      { name: "summary", path: "/training/summary" },
      { name: "feedback", path: "/training/feedback" },
    ],
  },
  {
    name: "manager",
    icon: ElementHub,
    children: [
      { name: "company_list", path: "/company/list" },
      { name: "user_list", path: "/company/userList" },
      { name: "create_company", path: "/company/createCompany" },
      { name: "download_practise", path: "/company/download" },
    ],
  },
];

export const navigationManagers = [
  { type: "label", label: "manager" },
  {
    name: "practice",
    icon: ElementHub,
    children: [
      { name: "training_list", path: "/training/list" },
      { name: "exam_list", path: "/training/examList" },
      { name: "result_list", path: "/training/report" },
      { name: "summary", path: "/training/summary" },
    ],
  },
  {
    name: "manager",
    icon: ElementHub,
    children: [
      { name: "company_list", path: "/company/list" },
      { name: "user_list", path: "/company/userList" },
      // { name: "Create Company", path: "/company/createCompany" },
      { name: "download_practise", path: "/company/download" },
    ],
  },
];

export const navigationStudents = [
  { type: "label", label: "student" },
  {
    name: "practice",
    icon: ElementHub,
    children: [
      { name: "training_list", path: "/training/list" },
      { name: "exam_list", path: "/training/examList" },
      // { name: "Report", path: "/training/report" },
    ],
  },
  // {
  //   name: "Manager",
  //   icon: ElementHub,
  //   children: [
  //     { name: "Company List", path: "/company/list" },
  //     { name: "Create Company", path: "/company/createCompany" },
  //     { name: "Download Practise", path: "/company/download" },
  //   ],
  // },
];
