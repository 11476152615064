import { Box, IconButton, MenuItem, Popover, styled } from "@mui/material";
import { H6 } from "../../../components/Typography";
import useAuth from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateLanguage } from "../../../services/userService";
import { SnackbarVariant } from "../../../utils/constants";

// dummy language options
const languageOptions: {
  [key: string]: { icon: string; label: string };
} = {
  en: {
    icon: "/static/flags/uk.png",
    label: "English",
  },
  // es: {
  //   icon: "/static/flags/spain.png",
  //   label: "Spanish",
  // },
  fi: {
    icon: "/static/flags/finland.png",
    label: "Finnish",
  },
};

// custom styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.action.hover },
}));

const IconWrapper = styled(Box)(() => ({
  display: "flex",
  height: 24,
  width: 24,
  padding: "2px",
  "& img": {
    width: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
}));

const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  "& img": { width: "100%" },
}));

const LanguagePopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[i18n.language]
  );
  const { user, updateUserInfo } = useAuth();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setOpen(false);
    updateLanguage(language).then((dataR) => {
      if (dataR) {
        enqueueSnackbar(t("saved"), {
          variant: SnackbarVariant.success,
        });
        updateUserInfo();
      } else {
        enqueueSnackbar(t("msg_07"), {
          variant: SnackbarVariant.error,
        });
      }
    });
  };

  useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user.language);
      setSelectedLanguage(languageOptions[user.language]);
    }
  }, [i18n, user]);

  return (
    <>
      <StyledIconButton onClick={handleOpen} ref={anchorRef}>
        <IconWrapper>
          <img alt={selectedLanguage.label} src={selectedLanguage.icon} />
        </IconWrapper>
      </StyledIconButton>
      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PaperProps={{ sx: { width: 150, padding: "0.5rem 0" } }}
      >
        {Object.keys(languageOptions).map((language: string) => (
          <MenuItem
            key={languageOptions[language].label}
            onClick={() => handleChangeLanguage(language)}
          >
            <ItemWrapper>
              <H6 fontWeight={600} ml={1}>
                {languageOptions[language].label}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
